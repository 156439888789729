/* Import Google Fonts */
/* Playfair, Dosis */
@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Playfair+Display:ital@0;1&display=swap');
/* 222831, 30475e, c1a57b, ececec */

/* Navigation Bar */
#navbar {
    background-color: #FFFFFF !important;
    font-family: 'Playfair Display', serif;
    height: 125px;
}
.nav-item {
    color: #222831 !important;
    font-family: 'Poppins', serif;
    font-size: 18px;
    margin-right: 20px;
}
.navbar-logo {
    height: 125px;
}


/* Details Section */
#details {
    text-align: center;    
    height: fit-content;
    background-color: #ececec;
    /* font-family: 'Playfair Display', serif; */
    font-family: 'Poppins';
    padding: 100px 10%;
}

.comp-desc {
    line-height: 2;
}

/* Features Section */
#features {
    background-color: #ececec;
}


/* Previous Work */
#prev-work {
    background-color: #ececec;
}

.prev-work-header {
    text-align: center;
    padding: 50px 0 50px;
    font-family: 'Playfair Display', serif;
}


/* Trusted Brands Section */
#brands {
    background-color: white;
    text-align: center;
    padding: 50px 100px 100px;
}

.brands-header {
    font-family: 'Playfair Display', serif;
    padding-bottom: 50px;
}
.brand-logo {
    height: 150px;
}
.brands-gallery {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-width: 25%;
    -moz-column-width: 25%;
    column-width: 25%;
    text-align: center;
}

/* Contact Section */
#contact {
    height: fit-content;
    padding: 100px 20%;
    background-color: #30475e;
    text-align: center;
    color: white;
}

.contact-header {
    font-family: 'Playfair Display', serif;
    padding-bottom: 25px;
    text-align: center;
}

.contact-value {
    padding-left: 3vw;
    line-height: 2;
}

/* Changes to be made on 
   specific screen sizes. */

@media (max-width: 992px) {
    .feature-box {
        padding-bottom: 50px;
    }
}


@media (max-width: 768px) {
    #brands {
        padding: 50px 0 100px;
    }
    
    .brands-gallery {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
    }

}
    
@media (max-width: 453px) {
    .title-header {
        font-size: 8vw;
    }
    .gallery {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
    }

    #contact {
        padding: 50px 0;
    }

}